export class Venta{
		public estado_producto: string;
		public estado_producto_unidad: string;
		public estado_sucursal: string;
		public estado_unidad: string;
		public id_producto_sucursal: string;
		public id_producto_unidad: string;
		public laboratorio: string;
		public nombre: string;
		public nombre_unidad: string;
		public precio: string;
		public producto_id: string;
		public representacion: string;
		public stock: string;
		public unidad_id: string;
		public dato_adicional:number;
}