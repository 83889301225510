import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-descuento-total-factura',
  templateUrl: './descuento-total-factura.component.html',
  styleUrls: ['./descuento-total-factura.component.css']
})
export class DescuentoTotalFacturaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
