import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { GLOBAL } from '../../services/global';
import { ToastrService } from 'ngx-toastr';
import { UsuarioService } from '../../services/usuario.service';
@Component({
  selector: 'app-pedido',
  templateUrl: './reporte.component.html',
  styleUrls: ['./reporte.component.css']
})
export class ReporteComponent implements OnInit {
  public ticket;
  public inicio:boolean;
  public preguntar:boolean;
  constructor(private toastr: ToastrService,  private _router: Router, private _usuarioService: UsuarioService){ 
    this.preguntar = false;
    this.inicio = true;
  }
  ngOnInit() {
  }
  showSuccess(titulo,mensaje) {
    this.toastr.success(mensaje, titulo);
  }
  showError(titulo,mensaje) {
    this.toastr.error(mensaje, titulo);
  }
  verConFecha(fecha): void {
      window.open("http://api.elvisalcantara.com/index.php/pedidos/pedido/obtenerPdfGenralCaja/"+fecha+"/"+this._usuarioService.getToken(), "_blank");
  }
  verConFechaDos(fecha_uno,fecha_dos): void {
      window.open("http://api.elvisalcantara.com/index.php/pedidos/pedido/obtenerPdfGenralCajaTime/"+fecha_uno+"/"+fecha_dos+"/"+this._usuarioService.getToken(), "_blank");
  }
}
